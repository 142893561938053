import {useEffect, useState} from "react";
import {MovieBoxLogo} from "../../assets";
import MovieCard, {MovieCardData} from "../../components/MovieCard";

import * as S from "./styles";

export default function Home() {
    // const [page, setPage] = useState<number>(1);
    const [configs, setConfigs] = useState([]);

    useEffect(() => {
        fetch('config.json')
            .then(response => response.json())
            .then(data => {
                const jsonData = data.slice().reverse();
                setConfigs(jsonData)
            })
            .catch(error => console.error('Error fetching data: ', error));
    }, []);

    return (
        <>
            <S.Header>
                <nav>
                    <MovieBoxLogo/>
                    <S.Link to="/favorites">Favorites</S.Link>
                </nav>
            </S.Header>

            <S.PageTitle>Movies</S.PageTitle>

            <S.Main>
                <S.MovieList>{
                    configs?.map(
                        ({id, poster_path, original_title, moive_path}: MovieCardData) => {
                            return (
                                <li key={id}>
                                    <MovieCard
                                        id={id}
                                        original_title={original_title}
                                        poster_path={poster_path}
                                        moive_path={moive_path}
                                    />
                                </li>
                            );
                        }
                    )
                }
                </S.MovieList>
                {/*<S.Navigation>*/}
                {/*    <S.NavigationButton*/}
                {/*        onClick={() => setPage((prevPage) => prevPage - 1)}*/}
                {/*        disabled={page === 1 ? true : false}*/}
                {/*    >*/}
                {/*        上一页*/}
                {/*    </S.NavigationButton>*/}
                {/*    <S.NavigationButton*/}
                {/*        onClick={() => setPage((prevPage) => prevPage + 1)}*/}
                {/*        disabled={false}*/}
                {/*    >*/}
                {/*        下一页*/}
                {/*    </S.NavigationButton>*/}
                {/*</S.Navigation>*/}
            </S.Main>
        </>
    );
}
