import {useLocation} from "react-router-dom";
import * as S from "./styles";

export default function Movie() {
    const href = useLocation();
    const path = href.pathname.split("/")[2];

    return (
        <>
            <video width="100%" height="100%" controls>
                <source src={"/videos/" + path} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </>
    );
}
